import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";
import RelatedPosts from "../components/blog/RelatedPosts";
import ContainerSmall from "../styles/ContainerSmall";
import StyledH1 from "../styles/StyledH1";
import PostText from "../styles/PostText";


const PostWrapper = styled(ContainerSmall)`


  blockquote {
    background: #fff;
    width: 286px;
    padding: 30px;
    box-sizing: border-box;
    margin: 0;
    @media(min-width: ${props => props.theme.screenSm}) {
       transform: translateX(-337px); // Align this in left gutter
       position: absolute;
    }
    h3 {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 130%;

      letter-spacing: 0.03em;
      color: #141414;
      margin-top: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  img {
    max-width: 100%;
  }


  .blog-highlight {
    font-family: Nunito Sans, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #0D3745;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 2px solid ${props => props.theme.blue};
    border-bottom:  2px solid ${props => props.theme.blue};
    margin-top: 50px;
    margin-bottom: 50px;
    @media(max-width: ${props => props.theme.screenSmMax}) {
      font-size: 1.75rem;
    }
  }
`;

const PostHeading = styled(StyledH1)`
    max-width: 873px;
    margin-top: 60px;
    margin-bottom: 30px;
`

const StyledDate = styled.p`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 150%;
  margin: 0;
  color: #0D3745;
`


const Subhead = styled.p`
  font-size: ${props => props.theme.fontMd}
`;

const blogpost = (props) => {

  const post = props.data.markdownRemark;

  let subhead = null;
  if (post.frontmatter.subhead) {
    subhead = <Subhead>{post.frontmatter.subhead}</Subhead>
  }

  let lastupdated = null;
  if (post.frontmatter.lastupdated) {
    lastupdated = <p><em>Last updated: {post.frontmatter.lastupdated}</em></p>
  }



  return (
    <Layout  grayFooter={true}>
      <PostWrapper>
        <article>
          <header>
            <PostHeading>
              {post.frontmatter.title}
            </PostHeading>
            <StyledDate>
              {post.frontmatter.date}
            </StyledDate>
            {lastupdated}
          </header>
          {subhead}
          <PostText dangerouslySetInnerHTML={{__html: post.html}}/>

        </article>

      </PostWrapper>
      <ContainerSmall>
        <RelatedPosts
          type={'blog'}
          currentSlug={props.pageContext.slug}
          tags={post.frontmatter.tags}
        />
      </ContainerSmall>
    </Layout>
  )

}

export default blogpost;


export const Head = (props) => {
  let seotitle = props.data.markdownRemark.frontmatter.title;
  if (props.data.markdownRemark.frontmatter.seotitle) {
    seotitle = props.data.markdownRemark.frontmatter.seotitle;
  }

  let description = props.data.markdownRemark.excerpt;
  if (props.data.markdownRemark.frontmatter.meta) {
    description = props.data.markdownRemark.frontmatter.meta;
  }


  return (
    <Seo
      title={seotitle}
      description={description}
      canonical={props.location.pathname}
    />
  )
}



export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        path
        seotitle
        subhead
        lastupdated
        tags
      }
    }
  }
`
